import { useUser } from '@clerk/clerk-react';
import { Link } from '@tanstack/react-router';
import {
  AtSign,
  BarChart3,
  BookMarked,
  ExternalLink,
  GitPullRequestCreateArrow,
  LayoutGrid,
  ReceiptText,
  Settings,
  Star,
  UserCog,
  Users,
} from 'lucide-react';

import { Separator } from '@/components/ui/separator';

import { Logo } from '../layout/logo';
import { Menu } from '../layout/menu';
import { ModeToggle } from '../layout/mode-toggle';
import { ProjectsSelect } from '../layout/projects-select';
import { UserMenu } from '../layout/user-menu';

const PROJECT_LINKS = [
  {
    title: 'Feature Requests',
    to: '/projects/$projectId/requests',
    Icon: GitPullRequestCreateArrow,
  },
  {
    title: 'Statistics',
    to: '/projects/$projectId/stats',
    Icon: BarChart3,
  },
  {
    title: 'Users',
    to: '/projects/$projectId/users',
    Icon: Users,
  },
  {
    title: 'Settings',
    to: '/projects/$projectId/settings',
    Icon: Settings,
  },
];

const GLOBAL_LINKS = [
  {
    title: 'Projects',
    to: '/projects',
    Icon: LayoutGrid,
  },
  {
    title: 'User settings',
    to: '/user-settings',
    Icon: UserCog,
  },
  {
    title: 'Billing',
    to: '/billing',
    Icon: ReceiptText,
  },
];

type Props = {
  type: 'projects' | 'global';
};

export const SidebarDesktop = ({ type }: Props) => {
  const { user, isLoaded } = useUser();

  return (
    <div className="fixed inset-0 right-auto flex w-[280px] flex-col justify-between overflow-y-auto bg-slate-200/40 px-4 pb-8 pt-3 dark:bg-gray-800/40">
      <div className="mb-5 flex items-center justify-between">
        <Logo />
        <ModeToggle />
      </div>

      <UserMenu />
      <Separator className="my-4" />

      <div className="mb-8 flex flex-1 flex-col">
        {type === 'projects' ? (
          <div className="mb-2">
            <ProjectsSelect />
          </div>
        ) : null}
        <Menu links={type === 'projects' ? PROJECT_LINKS : GLOBAL_LINKS} />
      </div>

      <div className="flex flex-1 flex-col justify-end gap-1">
        {isLoaded && user?.publicMetadata?.plan === 'free' ? (
          <Link
            to="/pricing"
            className="group mb-2 inline-flex items-center rounded-md bg-gradient-to-r from-purple-500 to-indigo-500 px-4 py-2 text-white transition-colors hover:from-purple-600 hover:to-indigo-600 dark:hover:from-purple-400 dark:hover:to-indigo-400"
            activeProps={{
              className: 'from-purple-600 to-indigo-600 dark:from-purple-400 dark:to-indigo-400',
            }}
          >
            <Star className="mr-2 h-4 w-4 fill-current text-white/75 transition-transform group-hover:scale-125" />
            <span className="transition-transform group-hover:translate-x-0.5">Upgrade</span>
          </Link>
        ) : null}

        <a
          href="https://docs.featurebuddy.com"
          target="_blank"
          className="group flex items-center space-x-2 py-2 text-slate-700 hover:text-black dark:text-slate-300 dark:hover:text-white"
        >
          <BookMarked
            className="text-slate-700 transition-colors group-hover:text-slate-800 dark:stroke-slate-400 group-hover:dark:stroke-slate-200"
            size={20}
          />
          <span>Docs</span>
          <ExternalLink size={16} />
        </a>

        <a
          href="mailto:hey@featurebuddy.com"
          className="group flex items-center space-x-2 py-2 text-slate-700 hover:text-black dark:text-slate-300 dark:hover:text-white"
        >
          <AtSign
            className="text-slate-700 transition-colors group-hover:text-slate-800 dark:stroke-slate-400 group-hover:dark:stroke-slate-200"
            size={20}
          />
          <span>Contact us</span>
        </a>
      </div>
    </div>
  );
};
